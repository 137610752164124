<template>
  <v-container>
    <v-row
      class="mt-10"
      data-aos="fade-up"
      data-aos-delay="50"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="true"
      data-aos-anchor-placement="top-center"
    >
      <v-col cols="12" md="3" lg="3">
        <v-card class="mx-auto rounded-xl" max-width="400" outlined>
          <!-- Baris pertama: Icon -->
          <v-card-title class="text-center icon">
            <v-icon size="80" :style="{ color: '#00738b' }"
              >mdi-chart-donut</v-icon
            >
          </v-card-title>
          <!-- Baris kedua: Angka -->
          <v-card-text
            class="text-center text-h2"
            :style="{ color: '#0c4f88' }"
          >
            {{ modalusaha }}
          </v-card-text>
          <!-- Baris ketiga: Deskripsi -->
          <v-card-text class="text-center title" :style="{ color: '#0c4f88' }">
            Miliar
          </v-card-text>
          <!-- Baris keempat: Button -->
          <v-card-actions class="justify-center d-flex flex-column">
            <v-btn
              rounded
              outlined
              color="primary"
              dark
              width="80%"
              :class="activeButton === 'dasar' ? 'active-button' : ''"
              @click="setModalUsaha('600', 'dasar')"
            >
              Modal Dasar
            </v-btn>
            <br />
            <v-btn
              rounded
              outlined
              color="primary"
              dark
              width="80%"
              class="mb-5"
              :class="activeButton === 'disetor' ? 'active-button' : ''"
              @click="setModalUsaha('150', 'disetor')"
            >
              Modal Disetor
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="3"
        lg="3"
        v-for="(item, index) in cards"
        :key="index"
      >
        <v-card class="mx-auto custom-card rounded-xl" max-width="400" outlined>
          <!-- Baris pertama: Icon -->
          <v-card-title class="text-center icon">
            <v-icon size="80" :style="{ color: '#00738b' }">{{
              item.icon
            }}</v-icon>
          </v-card-title>

          <!-- Baris kedua: Angka -->
          <v-card-text
            class="text-center text-h2"
            :style="{ color: '#0c4f88' }"
          >
            {{ item.number }}
          </v-card-text>

          <!-- Baris ketiga: Deskripsi -->
          <v-card-text class="text-center" :style="{ color: '#0c4f88' }">
            {{ item.description }}
          </v-card-text>

          <!-- Baris keempat: Button -->
          <v-card-actions class="justify-center">
            <v-btn
              rounded
              outlined
              color="primary"
              dark
              width="80%"
              @click="navigateTo(item.link)"
            >
              {{ item.button }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "SectionStory",
  data() {
    return {
      modalusaha: "600",
      activeButton: "dasar",
      cards: [
        {
          icon: "mdi-lan",
          number: 4,
          description: "Manajemen & Tenaga Ahli",
          button: "Tentang Kami",
          link: "tentang-kami",
        },
        {
          icon: "mdi-map-marker-check-outline",
          number: "-",
          description: "Kantor Pemasaran",
          button: "Jaringan Kami",
          link: "jaringan",
        },
        {
          icon: "mdi-package",
          number: 9,
          description: "Produk",
          button: "Produk Kami",
          link: "produk",
        },
      ],
    };
  },
  methods: {
    navigateTo(link) {
      if (link) {
        this.$router.push({ path: `/${link}` });
      } else {
        // Handle cases where link is empty
        alert("Link not available");
      }
    },
    setModalUsaha(value, button) {
      this.modalusaha = value;
      this.activeButton = button;
    },
  },
};
</script>

<style scoped>
.text-h3 {
  font-size: 24px;
}
.v-card__title {
  display: flex;
  justify-content: center;
}
.custom-card {
  padding: 40px 0px;
  cursor: pointer;
}
@media only screen and (max-width: 767.98px) {
  .text-h2 {
    font-size: 34px !important;
    line-height: 41px;
    padding-bottom: 0px;
  }
  .custom-card {
    padding: 15px 0px;
  }
}
.custom-card:hover .v-btn {
  background-color: #00738b !important;
  color: #fff !important;
}
.active-button {
  background-color: #00738b !important;
  color: #fff !important;
}
</style>
